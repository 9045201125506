"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
/**
 * Created on Jan 26, 2018
 * @author: Kayran
 */
window.requestUtils = {};
(function () {
  'use strict';

  function dependencies(window) {
    return {
      Sentry: window.Sentry
    };
  }
  var _dependencies = dependencies(window),
    Sentry = _dependencies.Sentry;

  /**
   * Object requestUtils
   *
   * This object controls the requests of all system.
   *
   * If you want to make some request with requestUtils calls requestData function.
   *
   * When the request returns some error (40X,50X) the requestUtils retry it because can be some error on GAE scaling and the second try can be successful
   * When the request returns empty or STATUS error, can be backend comunication error, then requestUtils retry it too
   * The retry proccess are made 5 times, in the fifth the request are definitely aborted until the client calls it again in the system.
   *
   * When the requests returns successfully, the requestData calls handleData function on the caller object.
   *
   * IF YOUR OBJ DOESN'T HAVE "handleData" FUNCTION AN ERROR WILL OCCURS
   *
   * The caller object handleData function needs to return "true" or "false", if the request data are the expected, the object needs to return true and the requestUtils will save the request data in the sessionStorage to avoid repeat the same request many times. Its strongly recommended to use the filter and other parameters of request on key, so that the requests with the same parameters will not be repeated, but if any parameter is changed, the sessionStorage will be ignored.
   *
   *
   * @type {{cacheTime: number, requestDataHistory: {}, ableToBindChangeState: boolean, lastRequest: string, cardRequests: {}, colordict: Array, bindChangeFilter: bindChangeFilter, requestData: requestData, callRecursiveRequestDataWithTimeOut: callRecursiveRequestDataWithTimeOut, incrementTimeout: incrementTimeout, isQuotaExceeded: isQuotaExceeded, putInSessionStorage: putInSessionStorage, putInLocalStorage: putInLocalStorage, checkIfNotExpired: checkIfNotExpired}}
   */
  window.requestUtils = {
    cacheTime: 10,
    // 10 secs for prod
    requestDataHistory: {},
    ableToBindChangeState: true,
    lastRequest: '',
    cardRequests: {},
    colordict: [],
    /**
     * This function call the reload function from all objects in the actual scope, when the GlobalFilter was changed
     */
    bindChangeFilter: function bindChangeFilter() {
      var _this = this;
      $(window).unbind('statechange.namespace');
      History.Adapter.bind(window, 'statechange', function () {
        // Note: We are using statechange instead of popstate
        if (typeof _this.requestDataHistory !== 'undefined' && Object.keys(_this.requestDataHistory).length > 0) {
          var filters = getUrlParameter('filters');
          var date_range = getUrlParameter('date_range');
          if (!(filters && date_range)) {
            return false;
          }
          for (var cObj in _this.requestDataHistory) {
            if (_this.requestDataHistory[cObj].cardName === 'channelselect') {
              continue;
            }
            if (_this.requestDataHistory[cObj].cardObj.reload) {
              _this.requestDataHistory[cObj].cardObj.reload(filters, date_range);
            } else {
              console.error('Reload function not find');
              window.Sentry.captureMessage('Reload function not find', {
                level: 'error',
                extra: {
                  cObj: cObj
                }
              });
              window.location.reload();
            }
          }
        }
      });
    },
    /**
     * This function makes a default request asking to GAE/Backend for data, and then returns the result of te request to the caller object
     * @param cardObj: the caller object that need some data from GAE/Backend
     * @param cardName: a name to identify the caller object, this name are used to store the ajax request to avoid more then one request from same card running in parallel
     * @param cardAjaxUrl: the URL to ask some data
     * @param objOfParameters: an object with all parameters to send in the request
     * @param sessionStorageKey: a key to the sessionStorage, with this key the requestData function verify if the request was recently made, and if it was, just return the data from previous request, IF IT NOT EXPIRED YET
     * @param skipEncoding whether to skip the filter encoding
     * @param timeOut: when the requestData receives an error, it is called recursively, only if timeout is less than 5. When the caller object calls requestData it doesn'n need to send timeout value, because this attribute was controlled by requestData.
     * @param allowStackedRequests: the request from the same card will be put on wait. It's used to allow previous request to cache data, and then the stacked request will load data from cache.
     */
    requestData: function requestData(cardObj, cardName, cardAjaxUrl, objOfParameters, sessionStorageKey) {
      var _this2 = this;
      var _ref = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {},
        skipEncoding = _ref.skipEncoding,
        _ref$timeOut = _ref.timeOut,
        timeOut = _ref$timeOut === void 0 ? 1 : _ref$timeOut,
        _ref$allowStackedRequ = _ref.allowStackedRequests,
        allowStackedRequests = _ref$allowStackedRequ === void 0 ? false : _ref$allowStackedRequ;
      var isThereRequestForCard = typeof this.cardRequests[cardName] !== 'undefined';
      if (isThereRequestForCard && !allowStackedRequests) {
        this.cardRequests[cardName].abort();
      }
      if (isThereRequestForCard && allowStackedRequests) {
        this.cardRequests[cardName].always(function () {
          // put on timeout because adding data to session storage is also on timeout
          setTimeout(function () {
            _this2.requestData(cardObj, cardName, cardAjaxUrl, objOfParameters, sessionStorageKey, {
              skipEncoding: skipEncoding,
              timeOut: 1,
              allowStackedRequests: false
            });
          }, 0);
        });
        return;
      }
      if (!objOfParameters) objOfParameters = {};

      // hide mentions container, if it exists
      if ($('.mentions-container').length) {
        $('.mentions-container').hide();
      }

      // console.log('request data ', cardName, cardAjaxUrl, objOfParameters);
      var _objOfParameters = $.extend({}, objOfParameters);

      //Save last requestData
      this.requestDataHistory[cardName] = {
        cardObj: cardObj,
        cardName: cardName,
        cardAjaxUrl: cardAjaxUrl,
        objOfParameters: _objOfParameters,
        sessionStorageKey: sessionStorageKey
      };

      //Bind only first request
      if (this.ableToBindChangeState) {
        this.bindChangeFilter();
        this.ableToBindChangeState = false;
      }
      var _this = this;
      var localStoreObjData, obj;
      var localStoreObj = sessionStorage.getItem(sessionStorageKey);

      //Check if is in localStorage
      if (localStoreObj) {
        localStoreObjData = JSON.parse(localStoreObj);
        //Check if is expired
        if (this.checkIfNotExpired(localStoreObjData.timestamp)) {
          console.info(cardName + ' in sessionStorage');
          cardObj.isWaitingRequest = false;
          // Hack to handle data asap but without broke execution
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#Zero_delays
          setTimeout(function () {
            cardObj.handleData(localStoreObjData.cardData);
          }, 0);
          return;
        } else {
          //Remove from localStorage
          sessionStorage.removeItem(sessionStorageKey);
        }
      }
      //If is not in localStorage or has expired
      console.warn(cardName + ' not in sessionStorage');
      if (!skipEncoding && typeof globalFilter !== 'undefined') {
        //Check if filter is undefined
        if (!_objOfParameters.filters) {
          //Set default filter
          console.warn('Filter is undefined, setting default.');
          _objOfParameters.filters = globalFilter.encodeFilter({}).id;
        }

        //Check if filter is obj
        if (_typeof(_objOfParameters.filters) === 'object') {
          //Encode it
          console.warn('Filter is json, encoding...');
          var encodedFilter = globalFilter.encodeFilter(_objOfParameters.filters);
          if (typeof encodedFilter !== 'undefined') _objOfParameters.filters = encodedFilter.id;
        }
      }

      //Encode parameters before send
      for (obj in _objOfParameters) {
        _objOfParameters[obj] = decodeURIComponent(_objOfParameters[obj]);
      }
      //console.info('Parameters for', cardName, _objOfParameters);

      //Start request
      this.cardRequests[cardName] = $.ajax({
        url: cardAjaxUrl,
        data: _objOfParameters,
        dataType: 'json',
        type: 'GET',
        beforeSend: function beforeSend() {
          clearTimeout(parent.GLOBAL_timer10);
          clearTimeout(parent.GLOBAL_timer30);
          clearTimeout(parent.GLOBAL_timer60);
          clearTimeout(parent.GLOBAL_timer120);
          clearTimeout(parent.GLOBAL_timer300);
          if (parent.GLOBAL_timer10 === '') {
            parent.GLOBAL_timer10 = setTimeout(function () {
              parent.createAndShowNotification('<b style="color:black;">Aguarde!</b><br>Estamos processando os dados.', 'info');
            }, 10 * 1000);
          }
          if (parent.GLOBAL_timer30 === '') {
            parent.GLOBAL_timer30 = setTimeout(function () {
              parent.createAndShowNotification('<b style="color:black;">Aguarde mais um pouco.</b><br>Ainda estamos processando.', 'info');
            }, 30 * 1000);
          }
        },
        success: function success(data) {
          //Clear notifications
          clearTimeout(parent.GLOBAL_timer10);
          clearTimeout(parent.GLOBAL_timer30);

          //Just to prevent and unexpected erro with encoding or data alteration
          var original_data, resultJson;
          try {
            original_data = jQuery.extend({}, data);
            resultJson = JSON.stringify(data);
            data = JSON.parse(resultJson);
          } catch (err) {
            console.error(err);
            original_data = data;
          }

          //Check for errors
          if (data.status === 'ERROR' || data.message.Error) {
            // If is message NULL is probable timeout, check if repetable too
            if (data.message === null || Object.prototype.hasOwnProperty.call(data.message, 'status_code') && data.message.status_code === 406) {
              _this.callRecursiveRequestDataWithTimeOut(cardObj, cardName, cardAjaxUrl, _objOfParameters, sessionStorageKey, {
                skipEncoding: skipEncoding,
                timeOut: timeOut
              });
              return false;
            }
            // Is backend ERROR, so do not make it again

            sessionStorage.removeItem(sessionStorageKey);

            //Probable backend bug
            var returnResponse = {
              status: 'ERROR',
              message: data ? data.message : null
            };
            if (data.message && _typeof(data.message) === 'object' && 'code' in data.message) returnResponse.code = data.message.code;

            // Hack to handle data asap but without broke execution
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#Zero_delays
            setTimeout(function () {
              cardObj.handleData(returnResponse, timeOut);
              cardObj.isWaitingRequest = false;
            }, 0);
            return false;
          } else {
            // Hack to handle data asap but without broke execution
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#Zero_delays
            setTimeout(function () {
              // Try to handle data
              if (cardObj.handleData(data, timeOut)) {
                //If success put in localStorage
                _this.putInSessionStorage(sessionStorageKey, {
                  cardData: original_data,
                  timestamp: Date.now()
                });
              }
              cardObj.isWaitingRequest = false;
            }, 0);
          }
        },
        error: function error(err, textStatus, errorThrown) {
          if (err.statusText === 'abort') return true;
          if (timeOut <= 4) {
            _this.callRecursiveRequestDataWithTimeOut(cardObj, cardName, cardAjaxUrl, _objOfParameters, sessionStorageKey, {
              skipEncoding: skipEncoding,
              timeOut: timeOut
            });
            return false;
          }
          console.error(cardAjaxUrl, cardName);
          console.error(err);
          console.error(textStatus);
          console.error(errorThrown);
          //Send an error back to the card
          cardObj.handleData({
            status: 'ERROR',
            message: null
          }, timeOut);
          cardObj.isWaitingRequest = false;
          return false;
        }
      });
    },
    /**
     * this is a accessory function to requestData, it recalls requestData with the same parameters of the first call, waiting some time depending on the value of timeOut
     * @param cardObj: the same cardObj sent to requestData previously
     * @param cardName: the same cardName sent to requestData previously
     * @param cardAjaxUrl: the same cardAjaxUrl sent to requestData previously
     * @param _objOfParameters: the same _objOfParameters sent to requestData previously
     * @param sessionStorageKey: the same sessionStorageKey sent to requestData previously
     * @param skipEncoding
     * @param timeOut: the counter of times the requestData was called
     */
    callRecursiveRequestDataWithTimeOut: function callRecursiveRequestDataWithTimeOut(cardObj, cardName, cardAjaxUrl, _objOfParameters, sessionStorageKey) {
      var _ref2 = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {},
        skipEncoding = _ref2.skipEncoding,
        _ref2$timeOut = _ref2.timeOut,
        timeOut = _ref2$timeOut === void 0 ? 1 : _ref2$timeOut;
      var _this = this;
      if (timeOut >= 9) {
        //If timeout bigger than 1 minute show notification
        createAndShowNotification('Falha de comunicação. Iremos tentar novamente em ' + timeOut + ' segundo' + (timeOut > 1 ? 's' : '') + '.', 'warning', timeOut * 1000, true);
      }
      setTimeout(function () {
        timeOut = _this.incrementTimeout(timeOut);
        _this.requestData(cardObj, cardName, cardAjaxUrl, _objOfParameters, sessionStorageKey, {
          skipEncoding: skipEncoding,
          timeOut: timeOut
        });
      }, timeOut * 1000);
    },
    /**
     * this function helps to control the time to wait until the next requestData call, the time can be 2s, 4s, 8s, 16s, 32s, 60s. After 60s ever wait 60s
     * @param timeOut
     * @returns {number}
     */
    incrementTimeout: function incrementTimeout(timeOut) {
      timeOut *= 2;
      if (timeOut > 60) timeOut = 60;
      return timeOut;
    },
    /**
     * this function verify if the sessionStorage or localStorage returns a quota error
     * @param e: error returned by sessionStorage or localStorage
     * @returns {boolean}
     */
    isQuotaExceeded: function isQuotaExceeded(e) {
      var quotaExceeded = false;
      if (e) {
        if ('code' in e) {
          switch (e.code) {
            case 22:
              quotaExceeded = true;
              break;
            case 1014:
              // Firefox
              if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                quotaExceeded = true;
              }
              break;
          }
        } else if (e.number === -2147024882) {
          // Internet Explorer 8
          quotaExceeded = true;
        }
      }
      return quotaExceeded;
    },
    /**
     * this function put the data from request on sessionStorage, when the request was completely successful
     * @param sessionStorageKey: the key to store ta data on sessionStorage
     * @param objToStore: the object with data to store
     */
    putInSessionStorage: function putInSessionStorage(sessionStorageKey, objToStore) {
      var _this = window.requestUtils;
      try {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(objToStore));
      } catch (err) {
        if (typeof _this.isQuotaExceeded === 'function' && _this.isQuotaExceeded(err)) {
          console.warn('Quota execeed, need to remove something from sessionStorage, finding oldest item.');
          var oldSize = sessionStorage.length;
          Object.keys(sessionStorage).forEach(function (key) {
            try {
              var localStoreObj = sessionStorage.getItem(key);
              var localStoreObjData = JSON.parse(localStoreObj);
              var timestamp = localStoreObjData.timestamp;
              if (_this.checkIfNotExpired(timestamp)) {
                sessionStorage.removeItem(key);
              }
            } catch (err) {
              console.error(err);
            }
          });
          var newSize = sessionStorage.length;
          //Check if cleared something, otherwise clear a random all
          if (oldSize >= newSize) sessionStorage.clear();else _this.putInSessionStorage(sessionStorageKey, objToStore);
        } else {
          console.error(err);
        }
      }
    },
    /**
     * this function put the data from request on localStorage, when the request was completely successful
     * @param localStorageKey: the key to store ta data on localStorage
     * @param objToStore: the object with data to store
     */
    putInLocalStorage: function putInLocalStorage(localStorageKey, objToStore) {
      var _this = window.requestUtils;
      try {
        localStorage.setItem(localStorageKey, objToStore);
      } catch (err) {
        if (typeof _this.isQuotaExceeded === 'function' && _this.isQuotaExceeded(err)) {
          console.warn('Quota execeed, need to remove something from localStorage, finding oldest item.');
          var oldSize = localStorage.length;
          Object.keys(localStorage).forEach(function (key) {
            try {
              var localStoreObj = localStorage.getItem(key);
              var localStoreObjData = JSON.parse(localStoreObj);
              var timestamp = localStoreObjData.timestamp;
              if (_this.checkIfNotExpired(timestamp)) {
                localStorage.removeItem(key);
              }
            } catch (err) {
              console.error(err);
            }
          });
          var newSize = localStorage.length;
          //Check if cleared something, otherwise clear a random all
          if (oldSize >= newSize) localStorage.clear();else _this.putInLocalStorage(localStorageKey, objToStore);
        } else {
          console.error(err);
        }
      }
    },
    /**
     * this function verify if the timestamp of the stored object was not expired
     *
     * when a data object was getted from sessionStorage or localStorage, it can't be older than 3 minutes on production or 24 hours on dev enviroment(alpha, local)
     *
     * @param timestamp:the timestamp of the data object
     * @returns {boolean}
     */
    checkIfNotExpired: function checkIfNotExpired(timestamp) {
      if (this.cacheTime < 1) {
        return false;
      }
      var old = new Date(timestamp);
      var now = Date.now();
      var timeInLocalStorageSec = (now - old) / 1000; //Sec
      var timeInLocalStorageMin = timeInLocalStorageSec / 60; //Min
      if (IS_DEVELOPMENT_VERSION) {
        console.info('Request is ' + timeInLocalStorageMin.toFixed(1) + ' minutes old');
        return timeInLocalStorageMin < 1440; // 24 horas para dev
      } else {
        return timeInLocalStorageSec < this.cacheTime; // 10 secs para prod
      }
    }
  };
})();